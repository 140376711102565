@import 'variables.scss';

body {
    height: 900px;
    margin: 0;
    font-family: myriad-pro,sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url(./assets/images/background.png) repeat top left #fff;
}

p, li {
    font-size: 0.9375rem;
    color: $color-2;
    letter-spacing: 1.5px;
    line-height: 20px;
}

a {
    text-decoration: none;
    color: $color-2;
}

#root {
    width: 100%;
}

