@import 'variables';

.TrainingVideo {
  display: flex;
  z-index: 2;
  flex-wrap: wrap;

  > div {
    width: 50vw;
    overflow: hidden;
    font-size: 1px;

    img {
      width: 100%;
      height: auto;
    }
  }

  video {
    width: 100%;
  }
}

.Player {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding-top: 40%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @include screen-32 {
    padding-top: 30%;
  }

  @include screen-32 {
    padding-top: 30%;
  }

  @include for-tablet-landscape-up {
    padding-top: 0;
    width: 80%;
  }

  @include for-desktop-up {
    max-width: 50%;
  }

  p {
    color: $color-5;
  }

  .subHeadline {
    display: inline;
    font-family: "TrueNorth", serif;
    font-size: 3rem;
    line-height: 3rem;
  }

  button {
    width: 100%;
    height: 35px;
    background-color: $color-10;
    color: $color-1;
    border-radius: 4px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 1rem;
  }
}

.isNotMobile {
  >div {
    max-width: 50%;
  }

  .Player {
    padding-top: 10%;
    max-width: 600px;
  }
}