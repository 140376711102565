@import 'variables';

.Impressum {
  padding: 1rem;
  width: calc(100% - 2rem);
.content {
  a {
    color: $color-4;
  }
  span {
    font-weight: bold;
  }
}
}