@import 'variables';

.home {
  padding-bottom: 3em;

  .moreInfos {
    width: 100%;
    min-height: 58.51vw;
    background: url(../../assets/images/moreInfos.svg);
    background-size: 118vw 58.51vh;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -1rem;

    .info, h3 {
      color: $color-5;
    }

    .moreInfosWrapper {
      margin: 0 1.3125rem;
      padding: 3.5rem 0;

      h3 {
        padding-top: 30px;
        margin: 0;
      }

      p {
        font-size: 0.75rem;
        margin-top: 0;
        line-height: 1rem;
      }
    }
  }

  .noCostsButton {
    width: 54.3vw;
    margin: -25px auto 0 auto;
    display: block;
    position: relative;
    z-index: 3;
    max-width: 250px;
  }


  .videoWrapper {
    video {
      width: 100%;
    }
  }

  .pickerAppPreview {
    width: 100vw;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin-top: -20%;

    img {
      width: 100%;
    }

    div {
      position: absolute;
      top: 47%;
      left: 1.5rem;
      width: 50%;

      p {
        color: $color-5;
        letter-spacing: 0.2px;
        line-height: 1.125rem;
      }
    }
  }

  .fibel {
    width: 100%;
    min-height: 58.51vw;
    background: url(../../assets/images/fibelBackground.svg);
    background-size: 118vw 58.51vh;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -6%;
    z-index: 4;
    position: relative;
    padding-top: 1px;

    h3 {
      padding-top: 3rem;
      padding-left: 1.5rem;
      margin-bottom: 0.5rem;
    }

    > p {
      padding: 0 1.5rem;
      margin-top: 0;
      margin-bottom: 0.25rem;
    }

    div a {
      margin: 0 auto;
      height: 45px;
      width: 60%;
      max-width: 285px;
      background: $color-5 0 0% no-repeat padding-box;
      box-shadow: 0 4px 5px $color-6;
      border-radius: 30px;
      text-align: center;
      line-height: 45px;
      display: flex;
      justify-content: stretch;
      align-items: center;

      img {
        margin-left: 1rem;
        margin-right: 10%;
        width: 10%;
      }

      p {
        font: normal normal bold 15px/20px myriad-pro;
        letter-spacing: 1.8px;
        color: $color-4;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }

  .experiences {
    padding: 7% 1.5rem 2rem 1.5rem;
    background-color: $color-5;
    margin-top: -7%;

    h2 {
      text-align: left;
      font: normal normal bold 1.25rem myriad-pro;
      letter-spacing: 0.8px;
      color: $color-4;
    }

    > p {
      color: $color-4;
      letter-spacing: 1.8px;
    }

    .moreExperiences {
      margin: 0 auto;
      height: 45px;
      width: 65%;
      max-width: 285px;
      border: 1px solid $color-4;
      background: $color-5;
      border-radius: 30px;
      text-align: center;
      line-height: 45px;
      //display: flex;
      justify-content: stretch;
      align-items: center;
      display: none;

      img {
        margin-left: 1rem;
        margin-right: 7%;
        width: 10%;
      }

      p {
        font: normal normal bold 15px/20px myriad-pro;
        letter-spacing: 1.8px;
        color: $color-4;
        text-transform: uppercase;
        text-decoration: none;
      }
    }

    .singleExperienceContainer {
      .singleExperience {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 1rem;

        &:nth-of-type(2n) {
          p {
            margin-left: 0;
          }
        }

        p {
          font-size: 0.75rem;
          letter-spacing: 1.44px;
          line-height: 20px;
          color: $color-2;
          margin-left: 150px;
          margin-top: 1.5rem;
          padding-right: 1.5rem;
          max-width: 450px;
          width: 50%;
        }

        .avatar {
          position: relative;

          .avatarBackground {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
            display: block;
          }

          .avatarImage {
            position: absolute;
            z-index: 11;
            display: block;
            border-radius: 50%;
            margin-left: 6px;
            margin-top: 11px;
          }
        }
      }
    }
  }

  .SocialMedia {
    margin: 1.5em 2em;
  }
}

.isNotMobile {
  .moreInfos {
    min-height: auto;

    .moreInfosWrapper {
      padding: 1rem 0;
    }
  }

  .fibel {
    min-height: auto;
    margin-top: 0;
    padding-bottom: 1rem;

    h3 {
      padding-top: 0;
    }

    div a {
      margin-top: 1rem;
    }
  }
  
  .pickerAppPreview{
    max-width: 100%;

    div {
      top: 50%;

      p {
        font-size: 1.2rem;
        line-height: 1.5;
      }
    }
  }

  .experiences {
    padding: 7% 0 2rem 6rem;
  }
}
