@import "variables.scss";

header {
  height: 109px;
  background: fade-out($color-2, 0.2);
  width: 100vw;
  z-index: 998;
  position: relative;

  .logo {
    width: 140px;
    margin: 0 auto;
    display: block;
  }

  .languageSelector {
    position: absolute;
    top: 0;
    right: 1.5em;
    width: 50px;

    > img {
      width: 100%;
    }
  }
}