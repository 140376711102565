@import 'variables';

.Tracking {
  background-color: #E3F2FD;
  color: #0D47A1;
  padding: 16px;
  margin: auto;
  margin-top: 2em;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  font-family: Arial, sans-serif;
  border-left: 4px solid #64B5F6;
  border-right: 4px solid #64B5F6;

  h2 {
    margin-top: 0;
    font-size: 1.5em;
    color: #1976D2;
  }

  p {
    margin-bottom: 0;
    color: #1565C0;
  }
}