// Body
$body-bg: #f8fafc;

// Typography
$font-size-base: 0.9375rem;
$line-height-base: 1.5;

// Colors
$color-1: rgba(229, 229, 229, 1); // #E5E5E5
$color-2: rgba(60, 60, 60, 1); // #3C3C3C
$color-3: rgba(0, 0, 0, 0.6); // #00000099
$color-4: rgba(108, 194, 71, 1); // #6CC247
$color-5: rgba(255, 255, 255, 1); // #FFFFFF
$color-6: rgba(0, 0, 0, 0.16); // #00000029
$color-7: rgba(0, 0, 0, 0); // #00000000
$color-8: rgba(0, 0, 0, 1); // #000000
$color-9: rgba(119, 255, 60, 1); // #77FF3C
$color-10: rgba(79, 175, 38, 1); // #4FAF26
$color-11: rgba(149, 255, 103, 1); // #95FF67
$color-12: rgba(255, 45, 22, 1); // #FF2D16
$color-13: rgba(255, 196, 62, 1); // #FFC43E
$color-14: rgba(165, 165, 165, 1); // #A5A5A5
$color-15: rgba(87, 197, 255, 1); // #57C5FF
$color-16: rgba(198, 234, 253, 1); // #C6EAFD

@mixin screen-25 {
  @media (min-width: 25em) { @content; }
}
@mixin screen-32 {
  @media (min-width: 31.25em) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}