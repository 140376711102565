@import 'variables.scss';

.wrapper {
  margin-bottom: 2rem;

  label {
    > div {
      width: 100%;

      > div {
        border-radius: 30px 0 0 30px !important;
        background: #eaeaea !important;
        outline: none !important;
        cursor: pointer;

        > div:hover {
          border-radius: 30px 0 0 30px !important;
          background-color: #eaeaea !important;
        }
      }

      button {
        border: none;
        width: 100%;
        background: transparent !important;
        border-radius: 30px 0 0 30px;
        outline: none !important;
        cursor: pointer;

        &:hover {
          background-color: transparent;
        }

        > div {
          margin: 0 auto;
          top: 0 !important;
          position: relative !important;
          margin-top: 20px !important;

          > div {
            top: 45% !important;
          }
        }
      }

      .country-list {
        height: auto !important;
      }

      input[type="tel"], input[type="text"] {
        border: 1px solid $color-2;
        border-radius: 30px;
        height: 45px;
        line-height: 45px;
        background: transparent;
        outline: none;
        width: 100%;
        margin-top: 0.5rem;

        &:focus {
          border-color: $color-2;
        }
      }
    }
  }

  .inputLabel {
    position: relative;
    display: block;

    p {
      padding: 0 1rem;
      margin-top: 0.5rem;
    }
  }

  .label {
    position: absolute;
    top: 0;
    left: 1.25rem;
    background-color: $color-2;
    color: $color-5;
    border-radius: 30px;
    font-size: 0.625rem;
    padding: 0 1rem;
    margin-top: -0.75rem;
    width: auto;
  }
}