@import "variables";

.appLink {
  width: 100%;
  text-align: center;

  img {
    width: 200px;
    margin: 2em 1em 0 0;
  }
}