@import "variables.scss";

.checked {
  border: 1px solid red;
}

.languageWrapper {
  width: 35%;
  margin-bottom: 2rem;

  p {
    font-weight: 700;
    text-transform: uppercase;
    color: $color-5;
    text-align: center;
    margin: 0.5rem 0 0;
  }
}

.flag {
  width: 100%;
}