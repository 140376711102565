@import "variables.scss";

.inputText {
  border: 1px solid $color-2;
  border-radius: 30px;
  height: 45px;
  line-height: 45px;
  background: transparent;
  padding-left: 1.125rem;
  width: calc(100% - 1.125rem);
  outline: none;
  margin-top: 0.5rem;
  display: block;
}

.inputLabel {
  position: relative;
  display: block;

  p {
    padding: 0 1rem;
    margin-top: 0.5rem;
  }
}

.label {
  position: absolute;
  top: 0;
  left: 1.25rem;
  background-color: $color-2;
  color: $color-5;
  border-radius: 30px;
  font-size: 0.625rem;
  padding: 0 1rem;
  margin-top: -0.75rem;
}