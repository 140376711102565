@import "variables.scss";

.footerLink {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 65px;
  background-color: $color-2;
  z-index: 1200;
  width: 100vw;
  text-align: center;
  display: flex;
  align-items: center;

  p {
    font-weight: 700;
    font-size: 1.35rem;
    text-transform: uppercase;
    color: $color-5;
    display: block;
    width: 88%;
    max-width: 600px;
    height: 45px;
    border-radius: 45px;
    line-height: 45px;
    margin: 0 auto;
    border: 1px solid $color-5;
    background-image: radial-gradient(
                    $color-9,
                    $color-10
    );
  }
}

.appLink {
  color: $color-1;
  padding-top: 20vh;
  max-width: 500px;
  margin: 0 auto;

  img {
    width: 100%;
    margin-bottom: 2em;
  }

  button {
    width: 100%;
    height: 10vh;
    background-color: $color-10;
    color: $color-1;
    border: none;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;

    @include for-tablet-landscape-up {
      max-width: 700px;
      margin: 0 auto;
      display: block;
    }
  }
}