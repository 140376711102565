@import "variables";

.footer {
  width: 100%;
  height: 125px;
  background-color: $color-1;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  a, p {
    display: block;
    font-size: 0.75rem;
    color: $color-2;
    margin: 0 auto;
    margin-top: 1.25rem;
    letter-spacing: 1.5px;
    line-height: 20px;
    cursor: pointer;
  }
}

.Contact {
  width: 100%;
  margin: 0 auto;

  .subHeadline {
    font-family: "TrueNorth", serif;
    font-size: 3rem;
    line-height: 2rem;
    color: $color-5;
    text-align: center;
  }

  .telephone {
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
  }

  .messenger {
    margin: 2em auto 0 auto;
    max-width: 70%;

    .icons {
      margin-bottom: 1em;
      display: flex;
      justify-content: center;

      .icon {
        margin: 0;
        &:first-of-type {
          margin-right: 1em;
        }
      }
    }

    .countries {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: right;

      a {
        display: block;
      }
    }
  }

  a {
    color: $color-5;
    font-size: 1.5rem;
    text-align: center;
    display: inline;
    cursor: pointer;
  }

  .symbol, .icon {
    width: 35px;
    margin-right: 1rem;
  }

  .icon {
    display: block;
    margin: 0 auto 0.5em auto;
  }

  div {
    margin: 0.75rem 0;
  }

  button {
    width: 100%;
    height: 35px;
    background-color: $color-10;
    color: $color-1;
    border-radius: 4px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
  }
}

.isNotMobile {
  > div {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .Contact {
    padding-top: 10%;
    max-width: 600px;
  }
}

