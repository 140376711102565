@import "variables.scss";

.ContactForm {
  background-color: $color-5;
  padding: 1em;
  position: relative;

  h3 {
    text-align: center;
    margin-bottom: 2em;
  }

  .flex {
    display: flex;
    justify-content: space-between;

    > div {
      width: 45%;
    }
  }

  .Button {
    border-radius: 35px;
    height: 35px;
    line-height: 35px;
    color: $color-5;
    text-transform: uppercase;
    font-weight: bold;
    background-color: $color-4;
    display: block;
    margin-top: 1rem;
    cursor: pointer;
    text-align: center;
  }

  .success, .error {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $color-5;
    top: 0;
    left: 0;
    z-index: 100;

    > div {
      background-color: fade-out($color-4, 0.4);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: $color-5;
        margin: 1em;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .error {
    > div {
      background-color: fade-out($color-12, 0.4);
    }
  }
}