@import "variables.scss";

.LanguagePopup {
  display: flex;
  width: 80%;
  margin: 0 auto;
  padding-top: 40%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.isNotMobile {
  padding-top: 10%;
  max-width: 600px;
}